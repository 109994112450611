import { Button, ButtonGroup, Col, Container, Link, PageBanner, Row, SocialButton, Thumbnail, Text, Title } from '@zigurous/react-components'; // prettier-ignore
import { Link as GatsbyLink, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { ImageProps, Page } from '../components';
import { trackEvent } from '../firebase/analytics';
import discordBanner from '../images/discord-banner.svg';

export const query = graphql`
  query LatestWork {
    json: allLatestWorkJson {
      latestWork: nodes {
        category
        identifier
        title
        subtitle
        image {
          sharp: childImageSharp {
            original {
              src
              width
              height
            }
          }
        }
      }
    }
  }
`;

function Home({ data, location }) {
  const { latestWork } = data.json;
  return (
    <Page className="home" location={location}>
      <PageBanner className="bg-surface-1">
        <Container fluid>
          <Row>
            <Col xl={10}>
              <Title size={2} tag="h1">
                Game development assets and tutorials
              </Title>
              <Text size="lg">
                We are an indie game developer creating Unity assets and
                tutorials to help new and experienced developers make games
                easier. Your support helps fund our own games alongside new
                assets and tutorials for the community.
              </Text>
              <ButtonGroup>
                <SocialButton
                  className="margin-top-md margin-bottom-md"
                  link="patreon"
                  onClick={() => {
                    trackEvent('social_button', {
                      content_type: 'button',
                      event_category: 'engagement',
                      event_label: 'Support Our Work',
                    });
                  }}
                  shape={SocialButton.shape.rounded}
                  style={SocialButton.style.outline}
                >
                  Support Our Work
                </SocialButton>
                <Link to="/assets" ElementType={GatsbyLink} unstyled>
                  <Button
                    onClick={() => {
                      trackEvent('link', {
                        content_type: 'button',
                        event_category: 'engagement',
                        event_label: 'Download Assets',
                      });
                    }}
                    shape={Button.shape.rounded}
                    size={Button.size.medium}
                    style={Button.style.outline}
                    tabIndex={-1}
                  >
                    Download Assets
                  </Button>
                </Link>
              </ButtonGroup>
            </Col>
          </Row>
        </Container>
      </PageBanner>
      <Container className="margin-top-2xxl margin-bottom-2xxl" fluid>
        <Row>
          <Col>{/* <Title size={5}>Latest Work</Title> */}</Col>
        </Row>
        <Row>
          {latestWork.map((entry, index) => (
            <Col
              className="margin-bottom-lg"
              key={entry.identifier}
              md={4}
              lg={3}
            >
              <Thumbnail
                alt={entry.title}
                animated
                aria-label={entry.title}
                ElementType={GatsbyLink}
                height={entry.image.sharp.original.height}
                image={entry.image.sharp.original.src}
                index={index}
                onClick={() => {
                  trackEvent('thumbnail', {
                    content_type: 'image',
                    event_category: 'engagement',
                    event_label: entry.title,
                    item_id: entry.identifier,
                  });
                }}
                to={`/${entry.category}/${entry.identifier}`}
                width={entry.image.sharp.original.width}
              />
              <Title bold marginTop="md">
                {entry.title}
              </Title>
              <Title eyebrow>{entry.subtitle}</Title>
            </Col>
          ))}
        </Row>
      </Container>
      <PageBanner
        className="discord-banner"
        size="xl"
        style={{
          background: `url(${discordBanner}), linear-gradient(0deg,#7196ff -.18%,#2a5ee8 97.44%)`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
        theme="dark"
      >
        <Container fluid>
          <Row>
            <Col md={6} lg={5}>
              <Title subtitle size={6} weight={500}>
                Game Developers
              </Title>
              <Title size={3}>Join our community!</Title>
              <Text size="lg">
                Our Discord server is a dedicated place to collaborate with
                other game developers, learn about making games, receive help
                and feedback, and much more! If you are looking for a community
                to grow alongside with, then this is a great place for you.
              </Text>
              <SocialButton
                className="margin-top-md margin-bottom-md"
                link="discord"
                onClick={() => {
                  trackEvent('social_button', {
                    content_type: 'button',
                    event_category: 'engagement',
                    event_label: 'Join Discord',
                  });
                }}
                primaryColor="white"
                secondaryColor="#7289da"
                shape={SocialButton.shape.rounded}
                style={SocialButton.style.outline}
              >
                Join Discord
              </SocialButton>
            </Col>
          </Row>
        </Container>
      </PageBanner>
    </Page>
  );
}

Home.propTypes = {
  data: PropTypes.shape({
    json: PropTypes.shape({
      latestWork: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.string,
          identifier: PropTypes.string,
          title: PropTypes.string,
          subtitle: PropTypes.string,
          image: ImageProps,
        })
      ),
    }),
  }),
  location: PropTypes.object,
};

export default Home;
